import * as React from "react"

import Layout from "../components/layout"
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {ImageWithText} from "../components/imageWithText";

const Contact = () => (
  <Layout>
    <Header pageName="contact" text="Kontakt"/>
    <ImageWithText
      header="Jeśli nie odbieram, proszę o cierpliwość, na pewno oddzwonię ;)"
      text={<></>}
      isContact
      imgSrc="/images/contact.jpg"
    />

    <iframe
      id="contact--maps"
      width="100%"
      height="281px"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6185.761055791267!2d19.979648996604787!3d49.318219789210765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715f269060acdf1%3A0x6377cf4768cf3309!2sHarenda%2015f%2C%2034-500%20Zakopane!5e0!3m2!1sen!2spl!4v1641435764992!5m2!1sen!2spl"
    />
    <Footer/>
  </Layout>
)

export default Contact
